><template>
	<div>
		<v-row>
			<v-col>
				<v-file-input v-model="file"></v-file-input>
                <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
			</v-col>
		</v-row>
		<v-snackbar v-model="uploaded" light>
			{{message}}
			<v-btn color="primary" text @click="uploaded = false">Close </v-btn>
		</v-snackbar>	
	</div>
</template>
<script>
	
	import { uploadFileToStorage } from "@/assets/storageFunctions.js";
	export default {
		name: "UploadField", 
		props: {
			location: {type: String}, 
			fileName: {type: String},
		},
		data: () => {
			return {
                dialog: false,
				file: null,
                loading: false,
				uploaded: false,
				message: "File Uploaded",
			}
		},
		computed: {
			filePath(){
				var fileName = this.fileName
				if( !this.fileName ){
					fileName = this.file.name;
				}
				var filePath = fileName;
                filePath = this.location + "/" +  filePath;
				return filePath;
			}
		},
		watch: {
			file(){
				this.uploadFile();
			},
		},
		methods: {
			uploadFile(){
				const self = this;
			
				if( !self.file ){
					return false;
				}
                self.loading = true;
				uploadFileToStorage( self.file, self.filePath ).then( file => {
					if( file ){
                        self.loading = false;
						self.message = "File Uploaded";
						self.uploaded = true;
						self.file = null;
						self.$emit('uploaded', file);
					}
				})
				
			},
		}, 
	}
</script>
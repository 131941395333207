<template>
	<v-component
		:is="isMultiple ? 'Multi' : 'Single'"
		v-bind="bind"
        @click="click"
	></v-component>
</template>
<script>
export default {
	name: "SectionNav",
	props: {
		id: { type: String }, // the id of the section
	},
	computed: {
		config() {
			return this.$store.state.config.data;
		},
		isMultiple() {
			return this.config.multiple[this.id];
		},
		section() {
			return this.$store.state.sections.data[this.id];
		},
        response(){
            return this.$store.state.assessment.data.responses[this.id];
        },
		responses() {
			return this.$store.state.sectionResponses.data;
		},
		bind() {
			if (!this.section) {
				return {};
			}
			if (this.isMultiple) {
				return {
					id: this.id,
				};
			} else {
				if( !this.responses[this.response] ){
					return {};
				}
				return {
					section: this.id,
					response: this.response,
					text: this.section.name,
					completion: this.responses[this.response].completion_rate,
					theme: true,
					status: true,
				};
			}
		},
	},
	data() {
		return {};
	},
	components: {
		Single: () => import("./ResponseNavItem.vue"),
		Multi: () => import("./ResponseNavGroup.vue"),
	},
	methods: {
        click(){
            if( !this.isMultiple ){
                this.$store.commit("GO_TO",  {section: this.id, response: this.response } )
            }
        }
    },
};
</script>

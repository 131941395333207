<template>
    <div>
        <p v-if="isLead">{{$t("messages.no_sections_lead")}}</p>
        <p v-else>{{$t("messages.no_sections_delegate")}}</p>
    </div>
</template>
<script>
export default {
    
    name: "NoSections", 
    computed: {
        isLead(){
            return this.$store.getters.isLead
        }, 
    }
}
</script>
<style scoped>
	.file:hover{
		text-decoration: underline;
		color: blue;
	}
</style>
<template>
	<v-list-item>
		<v-list-item-content>
			<v-list-item-title @click="downloadFile" class="file"><span class="font-weight-light">{{name}}</span></v-list-item-title>
            <v-list-item-subtitle v-if="description">
                {{description}}
            </v-list-item-subtitle>
		</v-list-item-content>
		<v-list-item-icon>
            <v-btn icon v-if="canDelete" @click="deleteFile">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
		</v-list-item-icon>
	</v-list-item>
</template>
<script>
	import {getFileFromStorage, deleteFileFromStorage} from "@/assets/storageFunctions.js";
	export default{
		name: 'FileListItem',
		props: {
            path: {type: String}, 
            name: {type: String },
            created_by: {type: String},
			description: {type: String},
		},
		computed: {
            user(){
				return this.$store.getters["auth/id"];
			},
			isLead(){
				return this.$store.getters.isLead
			},
            canDelete(){
                return this.isLead || this.user == this.created_by 
            }, 

		},
		methods: {
			deleteFile(){
				const self = this;
				deleteFileFromStorage(self.path).then( () => {
					this.$emit( "delete" )
				})
			},
			downloadFile(){
				const self = this;
				getFileFromStorage(self.path).then( url  => {
					var a = document.createElement("a");
					a.setAttribute("href", url )
					a.click()
				} ) 
			}
		}
	}
</script>

